import React, { useEffect, useState } from "react";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import {
   Box,
   Card,
   CardActionArea,
   CardActions,
   CardContent,
   IconButton,
   LinearProgress,
   Typography,
} from "@material-ui/core";
import Moment from "react-moment";
import {
   getFavoritesProjects,
   getPublishedProjects,
   getUnpublishedProjects,
   selectFavoritesPage,
   selectFavoritesPagination,
   selectFavoritesProjects,
   selectInitialPublished,
   selectPublishedPage,
   selectPublishedPagination,
   selectPublishedProjects,
   selectUnpublishedPage,
   selectUnpublishedPagination,
   selectUnpublishedProjects,
   setFavoritesPage,
   setFavoritesPagination,
   setPublishedPage,
   setPublishedPagination,
   setUnpublishedPage,
   setUnpublishedPagination,
} from "../ProjectsSlice";
import { useDispatch, useSelector } from "react-redux";
import "firebase/functions";
import firebase from "firebase/app";
import cuid from "cuid";
import ProjectDetailDialog from "../projectDetailDialog/ProjectDetailDialog";
import formatDate from "./FormatDate";
import useListStyles from "./ProjectListStyles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { selectUser } from "../../../features/authentication/authenticationSlice";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Pagination from "@material-ui/lab/Pagination";
import { useLocation } from "react-router-dom";

toast.configure();

export default function ProjectsList(userData) {
   const classes = useListStyles();
   const dispatch = useDispatch();
   const unpublishedProjects = useSelector(selectUnpublishedProjects);
   const publishedProjects = useSelector(selectPublishedProjects);
   const favoritesProjects = useSelector(selectFavoritesProjects);
   const initialPublished = useSelector(selectInitialPublished);
   const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);
   const [projectId, setProjectId] = useState<string>("");
   const userEmail = useSelector(selectUser).email;
   const [showFavorites, setShowFavorites] = useState<boolean>(true);
   const [showUnpublished, setShowUnpublished] = useState<boolean>(true);
   const [favoriteOpen, setFavoriteOpen] = useState<boolean>(false);
   const [query] = useState(new URLSearchParams(useLocation().search));
   const [containId, setContainId] = useState<boolean>();

   const data = userData.userData
   const isInsead = userData.userData?.isInsead
   const isEditor = userData.userData?.isEditor

   useEffect(() => {
      console.log(isInsead, isEditor, 44444)
   },[isInsead, isEditor])

   const filterActive =
      initialPublished.length === publishedProjects.length ? false : true;

   useEffect(() => {
      const pub = publishedProjects.filter(
         (project) => project.id === query.get("id")
      );
      const unPub = unpublishedProjects.filter(
         (project) => project.id === query.get("id")
      );
      const fav = favoritesProjects.filter(
         (project) => project.id === query.get("id")
      );
      setContainId(
         pub.length > 0 || unPub.length > 0 || fav.length > 0 ? true : false
      );
   }, [query, publishedProjects, unpublishedProjects, favoritesProjects]);

   function handleDetailDialog(id: string, favorite = false) {
      setFavoriteOpen(favorite);
      setDetailDialogOpen(true);
      setProjectId(id);
   }

   const projectsPerList = 12;

   const publishedPagination = useSelector(selectPublishedPagination);
   const favoritesPagination = useSelector(selectFavoritesPagination);
   const unpublishedPagination = useSelector(selectUnpublishedPagination);

   const publishedPage = useSelector(selectPublishedPage);
   const unpublishedPage = useSelector(selectUnpublishedPage);
   const favoritesPage = useSelector(selectFavoritesPage);

   useEffect(() => {
      dispatch(
         setPublishedPagination(
            publishedProjects.slice(
               publishedPage * projectsPerList - projectsPerList,
               publishedPage * projectsPerList
            )
         )
      );
      dispatch(
         setFavoritesPagination(
            favoritesProjects.slice(
               favoritesPage * projectsPerList - projectsPerList,
               favoritesPage * projectsPerList
            )
         )
      );
      if (isInsead && isEditor){
         dispatch(
            setUnpublishedPagination(
               unpublishedProjects.slice(
                  unpublishedPage * projectsPerList - projectsPerList,
                  unpublishedPage * projectsPerList
               )
            )
         );
      }
   }, [
      publishedProjects,
      unpublishedProjects,
      favoritesProjects,
      favoritesPage,
      unpublishedPage,
      publishedPage,
      dispatch,
      isInsead,
      isEditor
   ]);

   const handlePublishedPagination = (
      event: React.ChangeEvent<unknown>,
      value: number
   ) => {
      dispatch(setPublishedPage(value));
   };

   const handleFavoritesPagination = (
      event: React.ChangeEvent<unknown>,
      value: number
   ) => {
      dispatch(setFavoritesPage(value));
   };

   const handleUnpublishedPagination = (
      event: React.ChangeEvent<unknown>,
      value: number
   ) => {
      dispatch(setUnpublishedPage(value));
   };

   useEffect(() => {
      if (isInsead && isEditor){
         Promise.all([
            dispatch(getUnpublishedProjects()),
            dispatch(getFavoritesProjects(userEmail)),
            dispatch(getPublishedProjects()),
         ]).then(() => {
            if (query.get("id") && containId) {
               handleDetailDialog(query.get("id"));
            }
         });
      } 
      else if (isInsead && !isEditor){
         Promise.all([
            dispatch(getFavoritesProjects(userEmail)),
            dispatch(getPublishedProjects()),
         ]).then(() => {
            if (query.get("id") && containId) {
               handleDetailDialog(query.get("id"));
            }
         });
      }

   }, [userEmail, dispatch, query, containId, isInsead, isEditor]);

   function updateFavorites(project) {
      const prevFavorites = project?.favorites || [];
      let newFavorites = [...prevFavorites];
      if (prevFavorites.includes(userEmail)) {
         newFavorites = newFavorites.filter((user) => {
            return user !== userEmail;
         });
      } else {
         newFavorites.push(userEmail);
      }
      let db = firebase.firestore();
      let projectsRef = db.collection("business_projects").doc(project.id);
      projectsRef
         .update({
            favorites: newFavorites,
         })
         .then(function () {
            if (favoritesPagination.length === 1) {
               dispatch(setFavoritesPage(favoritesPage - 1));
            }
            if (isInsead & isEditor){
               dispatch(getUnpublishedProjects());
            }
            dispatch(getPublishedProjects());
            dispatch(getFavoritesProjects(userEmail));
         })
         .catch(function (error) {
            toast.error(error.message, { autoClose: 4000 });
         });
   }

   return (
      <>
         {!data && <LinearProgress />}
         {data && !isInsead && (
            <div>
               <NotInterestedIcon
                  style={{ paddingLeft: "30%", fontSize: "500px" }}
               />
            </div>
         )}
         {data && isInsead && (
            <Box>
               {favoritesProjects.length > 0 && (
                  <Box className='cardsList'>
                     <Box>
                        <Box display='flex'>
                           <Typography className={classes.allProjects}>
                              Favorites
                           </Typography>
                           <Typography className={classes.numberOfProjects}>
                              ({favoritesProjects.length})
                           </Typography>
                           <IconButton
                              onClick={() => setShowFavorites(!showFavorites)}
                           >
                              {showFavorites ? (
                                 <KeyboardArrowUpIcon />
                              ) : (
                                 <KeyboardArrowDownIcon />
                              )}
                           </IconButton>
                        </Box>
                        {showFavorites && (
                           <Box>
                              {favoritesPagination.map((project) => (
                                 <div key={cuid()} className='drawerFlex'>
                                    <Card
                                       variant='outlined'
                                       className={
                                          project.published
                                             ? classes.card
                                             : classes.cardUnpublished
                                       }
                                    >
                                       <CardActionArea
                                          className={classes.actionArea}
                                          onClick={() =>
                                             handleDetailDialog(
                                                project.id,
                                                true
                                             )
                                          }
                                       >
                                          <CardContent
                                             className={classes.content}
                                          >
                                             <Typography
                                                className={classes.title}
                                                variant='h5'
                                                component='h2'
                                             >
                                                {project.projectTitle}
                                             </Typography>
                                             <Typography
                                                className={classes.industries}
                                                color='textSecondary'
                                                component='div'
                                             >
                                                {Object.keys(project.industries)
                                                   .sort()
                                                   .join(", ")}
                                             </Typography>
                                          </CardContent>
                                       </CardActionArea>

                                       <CardActions className={classes.actions}>
                                          <Typography
                                             className={classes.createdAt}
                                             variant='body2'
                                             component='p'
                                          >
                                             <Moment
                                                fromNow
                                                className={classes.moment}
                                             >
                                                {project.published
                                                   ? formatDate(
                                                        project.firstPublishDate
                                                     )
                                                   : formatDate(
                                                        project.createdAt
                                                     )}
                                             </Moment>
                                          </Typography>
                                          <IconButton
                                             onClick={() =>
                                                updateFavorites(project)
                                             }
                                             className={
                                                project.favorites?.includes(
                                                   userEmail
                                                )
                                                   ? classes.redFavoriteButton
                                                   : classes.favoriteButton
                                             }
                                          >
                                             <FavoriteIcon />
                                             <Typography variant='subtitle2'>
                                                {project.favorites?.length > 0
                                                   ? project.favorites?.length
                                                   : null}
                                             </Typography>
                                          </IconButton>
                                       </CardActions>
                                    </Card>
                                 </div>
                              ))}
                           </Box>
                        )}
                     </Box>
                     {Math.ceil(favoritesProjects.length / projectsPerList) !==
                        1 && (
                        <Pagination
                           count={Math.ceil(
                              favoritesProjects.length / projectsPerList
                           )}
                           page={favoritesPage}
                           onChange={handleFavoritesPagination}
                        />
                     )}
                  </Box>
               )}
               {publishedProjects.length > 0 && (
                  <Box className='cardsList'>
                     <Box display='flex'>
                        <Typography className={classes.allProjects}>
                           {filterActive ? "Matching Projects" : "All Projects"}
                        </Typography>
                        <Typography className={classes.numberOfProjects}>
                           ({publishedProjects.length})
                        </Typography>
                     </Box>
                     {publishedPagination.map((project) => (
                        <div key={cuid()} className='drawerFlex'>
                           <Card className={classes.card} variant='outlined'>
                              <CardActionArea
                                 className={classes.actionArea}
                                 onClick={() => handleDetailDialog(project.id)}
                              >
                                 <CardContent className={classes.content}>
                                    <Typography
                                       className={classes.title}
                                       variant='h5'
                                       component='h2'
                                    >
                                       {project.projectTitle}
                                    </Typography>
                                    <Typography
                                       className={classes.industries}
                                       color='textSecondary'
                                       component='div'
                                    >
                                       {Object.keys(project.industries)
                                          .sort()
                                          .join(", ")}
                                    </Typography>
                                 </CardContent>
                              </CardActionArea>
                              <CardActions className={classes.actions}>
                                 <Typography
                                    className={classes.createdAt}
                                    variant='body2'
                                    component='p'
                                 >
                                    <Moment fromNow className={classes.moment}>
                                       {formatDate(project.firstPublishDate)}
                                    </Moment>
                                 </Typography>
                                 <IconButton
                                    onClick={() => updateFavorites(project)}
                                    className={
                                       project.favorites?.includes(userEmail)
                                          ? classes.redFavoriteButton
                                          : classes.favoriteButton
                                    }
                                 >
                                    <FavoriteIcon />
                                    <Typography variant='subtitle2'>
                                       {project.favorites?.length > 0
                                          ? project.favorites?.length
                                          : null}
                                    </Typography>
                                 </IconButton>
                              </CardActions>
                           </Card>
                        </div>
                     ))}
                     {Math.ceil(publishedProjects.length / projectsPerList) !==
                        1 && (
                        <Pagination
                           count={Math.ceil(
                              publishedProjects.length / projectsPerList
                           )}
                           page={publishedPage}
                           onChange={handlePublishedPagination}
                        />
                     )}
                  </Box>
               )}
               {data && isInsead && isEditor && unpublishedProjects.length > 0 && (
                  <Box className='cardsList'>
                     <Box display='flex'>
                        <Typography className={classes.allProjects}>
                           Unpublished Projects
                        </Typography>
                        <Typography className={classes.numberOfProjects}>
                           ({unpublishedProjects.length})
                        </Typography>
                        <IconButton
                           onClick={() => setShowUnpublished(!showUnpublished)}
                        >
                           {showUnpublished ? (
                              <KeyboardArrowUpIcon />
                           ) : (
                              <KeyboardArrowDownIcon />
                           )}
                        </IconButton>
                     </Box>
                     {showUnpublished && (
                        <Box>
                           {unpublishedPagination.map((project) => (
                              <div key={cuid()} className='drawerFlex'>
                                 <Card
                                    className={classes.cardUnpublished}
                                    variant='outlined'
                                 >
                                    <CardActionArea
                                       className={classes.actionArea}
                                       onClick={() =>
                                          handleDetailDialog(project.id)
                                       }
                                    >
                                       <CardContent className={classes.content}>
                                          <Typography
                                             className={classes.title}
                                             variant='h5'
                                             component='h2'
                                          >
                                             {project.projectTitle}
                                          </Typography>
                                          <Typography
                                             className={classes.industries}
                                             color='textSecondary'
                                             component='div'
                                          >
                                             {Object.keys(project.industries)
                                                .sort()
                                                .join(", ")}
                                          </Typography>
                                       </CardContent>
                                    </CardActionArea>
                                    <CardActions className={classes.actions}>
                                       <Typography
                                          className={classes.createdAt}
                                          variant='body2'
                                          component='p'
                                       >
                                          <Moment
                                             fromNow
                                             className={classes.moment}
                                          >
                                             {formatDate(project.createdAt)}
                                          </Moment>
                                       </Typography>
                                       <IconButton
                                          onClick={() =>
                                             updateFavorites(project)
                                          }
                                          className={
                                             project.favorites?.includes(
                                                userEmail
                                             )
                                                ? classes.redFavoriteButton
                                                : classes.favoriteButton
                                          }
                                       >
                                          <FavoriteIcon />
                                          <Typography variant='subtitle2'>
                                             {project.favorites?.length > 0
                                                ? project.favorites?.length
                                                : null}
                                          </Typography>
                                       </IconButton>
                                    </CardActions>
                                 </Card>
                              </div>
                           ))}
                           {Math.ceil(
                              unpublishedProjects.length / projectsPerList
                           ) !== 1 && (
                              <Pagination
                                 count={Math.ceil(
                                    unpublishedProjects.length / projectsPerList
                                 )}
                                 page={unpublishedPage}
                                 onChange={handleUnpublishedPagination}
                              />
                           )}
                        </Box>
                     )}
                  </Box>
               )}
            </Box>
         )}
         {detailDialogOpen && (
            <ProjectDetailDialog
               userData={data}
               id={projectId}
               open={detailDialogOpen}
               setDialogOpen={setDetailDialogOpen}
               userEmail={userEmail}
               favoriteOpen={favoriteOpen}
               projectsPerList={projectsPerList}
            />
         )}
      </>
   );
}
