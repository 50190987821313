import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import firebase from "firebase/app";
import "firebase/firestore";

interface Data {
   unpublishedProjects: Object[];
   publishedProjects: Object[];
   initialPublished: Object[];
   favoritesProjects: Object[];
   currentProject: Object;
   scenery: string;
   publishedPage: number;
   unpublishedPage: number;
   favoritesPage: number;
   publishedPagination: object[];
   unpublishedPagination: object[];
   favoritesPagination: object[];
}

export const initialState: Data = {
   unpublishedProjects: [],
   initialPublished: [],
   publishedProjects: [],
   favoritesProjects: [],
   currentProject: {},
   scenery: "view",
   publishedPage: 1,
   unpublishedPage: 1,
   favoritesPage: 1,
   publishedPagination: [],
   unpublishedPagination: [],
   favoritesPagination: [],
};

export const projectsSlice = createSlice({
   name: "projects",
   initialState,
   reducers: {
      setUnpublishedProjects: (state, action) => {
         state.unpublishedProjects = action.payload;
      },
      setPublishedProjects: (state, action) => {
         state.publishedProjects = action.payload;
      },
      setInitialPublished: (state, action) => {
         state.initialPublished = action.payload;
      },
      setFavoritesProjects: (state, action) => {
         state.favoritesProjects = action.payload;
      },
      setCurrentProject: (state, action) => {
         state.currentProject = action.payload;
      },
      setScenery: (state, action) => {
         state.scenery = action.payload;
      },
      setPublishedPage: (state, action) => {
         state.publishedPage = action.payload;
      },
      setUnpublishedPage: (state, action) => {
         state.unpublishedPage = action.payload;
      },
      setFavoritesPage: (state, action) => {
         state.favoritesPage = action.payload;
      },
      setPublishedPagination: (state, action) => {
         state.publishedPagination = action.payload;
      },
      setUnpublishedPagination: (state, action) => {
         state.unpublishedPagination = action.payload;
      },
      setFavoritesPagination: (state, action) => {
         state.favoritesPagination = action.payload;
      },
   },
});

export const {
   setUnpublishedProjects,
   setPublishedProjects,
   setInitialPublished,
   setFavoritesProjects,
   setCurrentProject,
   setScenery,
   setPublishedPage,
   setUnpublishedPage,
   setFavoritesPage,
   setPublishedPagination,
   setUnpublishedPagination,
   setFavoritesPagination,
} = projectsSlice.actions;

export const getUnpublishedProjects = (): AppThunk => (dispatch) => {
   var arrayResult = [];
   var resultStore = firebase.firestore();
   resultStore
      .collection("business_projects")
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (!data.published) {
               data.createdAt = data.createdAt?.toDate().toString();
               data.startDate = data.startDate?.toDate().toString();
               data.firstPublishDate = data.firstPublishDate
                  ?.toDate()
                  .toString();
               data.id = doc.id;
               arrayResult.push(data);
            }
         });
         dispatch(setUnpublishedProjects(arrayResult));
      });
};

export const getPublishedProjects = (): AppThunk => (dispatch) => {
   var arrayResult = [];
   var resultStore = firebase.firestore();
   resultStore
      .collection("business_projects")
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.published) {
               data.createdAt = data.createdAt?.toDate().toString();
               data.startDate = data.startDate?.toDate().toString();
               data.firstPublishDate = data.firstPublishDate
                  ?.toDate()
                  .toString();
               data.id = doc.id;
               arrayResult.push(data);
            }
         });
         dispatch(setPublishedProjects(arrayResult));
      });
};

export const getInitialPublished = (): AppThunk => (dispatch) => {
   var arrayResult = [];
   var resultStore = firebase.firestore();
   resultStore
      .collection("business_projects")
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.published) {
               data.createdAt = data.createdAt?.toDate().toString();
               data.startDate = data.startDate?.toDate().toString();
               data.firstPublishDate = data.firstPublishDate
                  ?.toDate()
                  .toString();
               data.id = doc.id;
               arrayResult.push(data);
            }
         });
         dispatch(setInitialPublished(arrayResult));
      });
};

export const getFavoritesProjects = (userEmail): AppThunk => (dispatch) => {
   var arrayResult = [];
   var resultStore = firebase.firestore();
   resultStore
      .collection("business_projects")
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.favorites?.includes(userEmail)) {
               data.createdAt = data.createdAt?.toDate().toString();
               data.startDate = data.startDate?.toDate().toString();
               data.firstPublishDate = data.firstPublishDate
                  ?.toDate()
                  .toString();
               data.id = doc.id;
               arrayResult.push(data);
            }
         });
         dispatch(setFavoritesProjects(arrayResult));
      });
};

export const getFilteredProjects = (
   industries: string[],
   userEmail: string,
   type: string,
   sort: string,
   search: string,
   paid: string
): AppThunk => (dispatch) => {
   const lowerCaseSearch = search.toLowerCase();
   let arrayPublished = [];
   let arrayUnpublished = [];
   let arrayFavorites = [];
   var resultStore = firebase.firestore();
   const collectionRef = resultStore.collection("business_projects");
   let query: firebase.firestore.Query = collectionRef;

   if (type !== "all") {
      query = query.where("type", "==", type);
   }

   if (paid === "paid") {
      query = query.where("paid", "==", true);
   } else if (paid === "unpaid"){
      query = query.where("paid", "==", false);
   } 

   industries.forEach(
      (industry) => (query = query.where(`industries.${industry}`, "==", true))
   );

   query.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
         const data = doc.data();
         const dataFav = doc.data();
         data.createdAt = data.createdAt?.toDate().toString();
         data.startDate = data.startDate?.toDate().toString();
         data.firstPublishDate = data.firstPublishDate?.toDate().toString();
         data.id = doc.id;
         if (data.published) {
            arrayPublished.push(data);
         } else {
            arrayUnpublished.push(data);
         }

         if (dataFav.favorites?.includes(userEmail)) {
            dataFav.createdAt = dataFav.createdAt?.toDate().toString();
            dataFav.startDate = dataFav.startDate?.toDate().toString();
            dataFav.firstPublishDate = dataFav.firstPublishDate
               ?.toDate()
               .toString();
            dataFav.id = doc.id;
            arrayFavorites.push(data);
         }
      });

      if (search.length > 0) {
         arrayPublished = arrayPublished.filter(
            (project) =>
               project.projectDescription
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0 ||
               project.projectTitle.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.companyDescription
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0 ||
               project.companyWebsite.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.deliverable.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.location.toLowerCase().indexOf(lowerCaseSearch) >= 0 ||
               project.projectSponsor.name
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0
         );
         arrayFavorites = arrayFavorites.filter(
            (project) =>
               project.projectDescription
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0 ||
               project.projectTitle.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.companyDescription
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0 ||
               project.companyWebsite.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.deliverable.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.location.toLowerCase().indexOf(lowerCaseSearch) >= 0 ||
               project.projectSponsor.name
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0
         );
         arrayUnpublished = arrayUnpublished.filter(
            (project) =>
               project.projectDescription
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0 ||
               project.projectTitle.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.companyDescription
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0 ||
               project.companyWebsite.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.deliverable.toLowerCase().indexOf(lowerCaseSearch) >=
                  0 ||
               project.location.toLowerCase().indexOf(lowerCaseSearch) >= 0 ||
               project.projectSponsor.name
                  .toLowerCase()
                  .indexOf(lowerCaseSearch) >= 0
         );
      }

      arrayPublished.sort(function (a, b) {
         const c: any = new Date(a.createdAt);
         const d: any = new Date(b.createdAt);
         return sort === "earliest" ? d - c : c - d;
      });
      arrayFavorites.sort(function (a, b) {
         const c: any = new Date(a.createdAt);
         const d: any = new Date(b.createdAt);
         return sort === "earliest" ? d - c : c - d;
      });
      arrayUnpublished.sort(function (a, b) {
         const c: any = new Date(a.createdAt);
         const d: any = new Date(b.createdAt);
         return sort === "earliest" ? d - c : c - d;
      });
      dispatch(setPublishedProjects(arrayPublished));
      dispatch(setUnpublishedProjects(arrayUnpublished));
      dispatch(setFavoritesProjects(arrayFavorites));
   });
};

export const selectInitialPublished = (state: any) =>
   state.projectsReducer.initialPublished;
export const selectUnpublishedProjects = (state: any) =>
   state.projectsReducer.unpublishedProjects;
export const selectPublishedProjects = (state: any) =>
   state.projectsReducer.publishedProjects;
export const selectFavoritesProjects = (state: any) =>
   state.projectsReducer.favoritesProjects;
export const selectCurrentProject = (state: any) =>
   state.projectsReducer.currentProject;
export const selectScenery = (state: any) => state.projectsReducer.scenery;
export const selectPublishedPage = (state: any) =>
   state.projectsReducer.publishedPage;
export const selectUnpublishedPage = (state: any) =>
   state.projectsReducer.unpublishedPage;
export const selectFavoritesPage = (state: any) =>
   state.projectsReducer.favoritesPage;
export const selectPublishedPagination = (state: any) =>
   state.projectsReducer.publishedPagination;
export const selectUnpublishedPagination = (state: any) =>
   state.projectsReducer.unpublishedPagination;
export const selectFavoritesPagination = (state: any) =>
   state.projectsReducer.favoritesPagination;
export default projectsSlice.reducer;
