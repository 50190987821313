import React, { useEffect, useState } from "react";
import {
   Dialog,
   DialogContent,
   Button,
   Box,
   Typography,
   MenuItem,
   IconButton,
   FormControlLabel,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Select, Switch, TextField } from "formik-material-ui";
import firebase from "firebase/app";
import "firebase/functions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "formik-material-ui-pickers";
import "./ProjectsDialog.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PlacesAutocomplete from "react-places-autocomplete";
import cuid from "cuid";
import * as Yup from "yup";
import { selectUser } from "../../features/authentication/authenticationSlice";
import { getUnpublishedProjects } from "./ProjectsSlice";
import CloseIcon from "@material-ui/icons/Close";

toast.configure();

interface Values {
   companyName: string;
   companyWebsite: string;
   companyDescription: string;
   sponsorName: string;
   sponsorRole: string;
   sponsorEmail: string;
   projectTitle: string;
   projectDescription: string;
   startDate: Date;
   industries: string[];
   type: string;
   duration: number;
   effort: string;
   location: string;
   paid: boolean;
   deliverable: string;
}

const MenuProps = {
   getContentAnchorEl: null,
   anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
   },
   MenuListProps: {
      tabIndex: "1",
   },
};

export default function NewProposalDialog({ open, setDialogOpen, userData }) {
   const [disabled, setDisabled] = useState(false);
   const [address, setAddress] = React.useState("Virtual");
   const dispatch = useDispatch();

   const user = useSelector(selectUser);

   const isInsead = userData.userData?.isInsead;
   const isEditor = userData.userData?.isEditor;

   function handleDialogClose() {
      const className = document.getElementById("submitButton")?.className;
      const materialReturn = className?.substr(className.length - 8);
      const buttonDisabled = materialReturn === "disabled" ? true : false;

      if (buttonDisabled) {
         setDialogOpen(false);
      } else {
         let conf = window.confirm(
            "You have unsaved changes, are you sure you want to leave?"
         );
         if (conf) {
            setDialogOpen(false);
         } else {
            return;
         }
      }
   }

   useEffect(() => {
      if (address === "") {
         setDisabled(true);
      } else {
         setDisabled(false);
      }
   }, [address]);

   const handleSelect = async (value) => {
      setAddress(value);
   };

   const initialValues: Values = {
      projectTitle: "",
      companyName: "",
      companyWebsite: "",
      industries: [],
      type: "Commercial",
      duration: 1,
      effort: "1-2 hours per week",
      location: "",
      paid: false,
      deliverable: "Strategy deck",
      startDate: new Date(),
      projectDescription: "",
      sponsorName: "",
      sponsorRole: "",
      sponsorEmail: user?.email || "",
      companyDescription: "",
   };

   const validationSchema = Yup.object().shape({
      projectTitle: Yup.string().required("Required Field"),
      industries: Yup.array().required(
         "You need to choose at least one industry"
      ),
      type: Yup.string().required("Required Field"),
      deliverable: Yup.string().required("Required Field"),
      projectDescription: Yup.string().required("Required Field"),
      sponsorName: Yup.string().required("Required Field"),
      sponsorEmail: Yup.string().email().required("Required Field"),
      duration: Yup.number().min(1).max(16).required("Required Field"),
      companyWebsite: Yup.string().matches(
         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
         "Enter correct url!"
      ),
   });

   return (
      <Dialog
         open={open}
         onClose={handleDialogClose}
         aria-labelledby='alert-dialog-title'
         aria-describedby='alert-dialog-description'
      >
         <DialogContent>
            <Formik
               validationSchema={validationSchema}
               initialValues={initialValues}
               onSubmit={async (values) => {
                  setDisabled(true);
                  values.location = address;
                  let newDoc = {};

                  newDoc["projectTitle"] = values.projectTitle;
                  newDoc["companyName"] = values.companyName;
                  newDoc["companyWebsite"] = values.companyWebsite;

                  let industriesObj = {};
                  values.industries.map(
                     (industry) => (industriesObj[industry] = true)
                  );
                  newDoc["industries"] = industriesObj;

                  newDoc["type"] = values.type;
                  newDoc["duration"] = values.duration;
                  newDoc["effort"] = values.effort;
                  newDoc["location"] = values.location;
                  newDoc["paid"] = values.paid;
                  newDoc["deliverable"] = values.deliverable;
                  newDoc["startDate"] = values.startDate;
                  newDoc["projectDescription"] = values.projectDescription;
                  newDoc["projectSponsor"] = {
                     email: values.sponsorEmail,
                     name: values.sponsorName,
                     role: values.sponsorRole,
                  };
                  newDoc["companyDescription"] = values.companyDescription;

                  newDoc["published"] = false;
                  newDoc["createdAt"] = new Date();

                  let db = firebase.firestore();
                  let projectsRef = db.collection("business_projects").doc();
                  projectsRef
                     .set(newDoc)
                     .then(function () {
                        setDialogOpen(false);
                        toast.success("Data successfully sent! 🚀🚀", {
                           autoClose: 2000,
                        });
                        if (isInsead && isEditor) {
                           dispatch(getUnpublishedProjects());
                        }
                     })
                     .catch(function (error) {
                        setDialogOpen(false);
                        toast.error(error.message, { autoClose: 4000 });
                     });
               }}
            >
               {({ submitForm, isValid, dirty, setFieldValue }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     <Form>
                        <Box id='greenHead'>
                           <IconButton
                              id='topButtonsBox'
                              className='topButtons closeIconButton'
                              onClick={() => setDialogOpen(false)}
                           >
                              <CloseIcon />
                           </IconButton>
                           <Box
                              id='greenHeadMainChild'
                              margin={0}
                              display='block'
                           >
                              <Box
                                 id='projectTitleBox'
                                 margin={0}
                                 display='flex'
                              >
                                 <Field
                                    component={TextField}
                                    name='projectTitle'
                                    type='text'
                                    variant='outlined'
                                    id='projectTitle'
                                    placeholder='Project Title*'
                                 />
                              </Box>
                              <Box
                                 id='companyBox'
                                 margin={0}
                                 display='flex'
                                 pr={2}
                                 pl={2}
                              >
                                 <Field
                                    component={TextField}
                                    name='companyName'
                                    type='text'
                                    variant='outlined'
                                    id='companyName'
                                    placeholder='Company Name (if involved)'
                                 />{" "}
                                 <Field
                                    component={TextField}
                                    name='companyWebsite'
                                    type='text'
                                    variant='outlined'
                                    id='companyWebsite'
                                    placeholder='Company Website'
                                 />
                              </Box>
                           </Box>
                        </Box>
                        <Box id='opacityGreen'>
                           <Box>
                              <Typography className='opacityGreenTitle'>
                                 Industries (one or more)*
                              </Typography>
                              <Field
                                 component={Select}
                                 multiple={true}
                                 MenuProps={{
                                    ...MenuProps,
                                    disableAutoFocusItem: true,
                                 }}
                                 name='industries'
                                 variant='outlined'
                                 type='text'
                                 className='opacityGreenField'
                              >
                                 <MenuItem value='Agriculture & Food'>
                                    Agriculture & Food
                                 </MenuItem>
                                 <MenuItem value='Consulting'>
                                    Consulting
                                 </MenuItem>
                                 <MenuItem value='Consumer & Luxury Goods'>
                                    Consumer & Luxury Goods
                                 </MenuItem>
                                 <MenuItem value='Education'>
                                    Education
                                 </MenuItem>
                                 <MenuItem value='Energy'>Energy</MenuItem>
                                 <MenuItem value='Finance'>Finance</MenuItem>
                                 <MenuItem value='Fintech'>Fintech</MenuItem>
                                 <MenuItem value='Healthcare'>
                                    Healthcare
                                 </MenuItem>
                                 <MenuItem value='Industrials'>
                                    Industrials
                                 </MenuItem>
                                 <MenuItem value='Media & Telecom'>
                                    Media & Telecom
                                 </MenuItem>
                                 <MenuItem value='PE & VC'>PE & VC</MenuItem>
                                 <MenuItem value='Retail'>Retail</MenuItem>
                                 <MenuItem value='Social Impact'>
                                    Social Impact
                                 </MenuItem>
                                 <MenuItem value='Tech'>Tech</MenuItem>
                              </Field>
                           </Box>
                           <Box>
                              <Typography className='opacityGreenTitle'>
                                 Type*
                              </Typography>
                              <Field
                                 component={TextField}
                                 select
                                 name='type'
                                 variant='outlined'
                                 type='text'
                                 className='opacityGreenField'
                              >
                                 <MenuItem value='Individual Study Project'>
                                    Individual Study Project
                                 </MenuItem>
                                 <MenuItem value='Commercial'>
                                    Commercial
                                 </MenuItem>
                                 <MenuItem value='Startup'>Startup</MenuItem>
                              </Field>
                           </Box>
                           <Box>
                              <Typography className='opacityGreenTitle'>
                                 Duration (1-16 weeks)*
                              </Typography>
                              <Field
                                 component={TextField}
                                 name='duration'
                                 onChange={(e) => {
                                    const { value } = e.target;
                                    if (!value || (value > 0 && value < 17)) {
                                       setFieldValue("duration", value);
                                    }
                                 }}
                                 type='number'
                                 variant='outlined'
                                 className='opacityGreenField'
                              />
                           </Box>
                           <Box>
                              <Typography className='opacityGreenTitle'>
                                 Effort (weekly effort)
                              </Typography>
                              <Field
                                 component={TextField}
                                 select
                                 name='effort'
                                 variant='outlined'
                                 type='text'
                                 className='opacityGreenField'
                              >
                                 <MenuItem value='1-2 hours per week'>
                                    1-2 hours per week
                                 </MenuItem>
                                 <MenuItem value='2-5 hours per week'>
                                    2-5 hours per week
                                 </MenuItem>
                                 <MenuItem value='5-10 hours per week'>
                                    5-10 hours per week
                                 </MenuItem>
                                 <MenuItem value='10+ hours per week'>
                                    10+ hours per week
                                 </MenuItem>
                              </Field>
                           </Box>

                           <Box>
                              <Typography className='opacityGreenTitle'>
                                 Location*
                              </Typography>{" "}
                              <PlacesAutocomplete
                                 value={address}
                                 onChange={setAddress}
                                 onSelect={handleSelect}
                              >
                                 {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                 }) => (
                                    <div>
                                       <Field
                                          {...getInputProps()}
                                          component={TextField}
                                          name='location'
                                          type='text'
                                          variant='outlined'
                                          placeholder='Location'
                                          className='opacityGreenField'
                                       />{" "}
                                       <div>
                                          {loading ? (
                                             <div>...loading</div>
                                          ) : null}

                                          {suggestions.map((suggestion) => {
                                             const style = {
                                                backgroundColor: suggestion.active
                                                   ? "#27e650"
                                                   : "#fff",
                                                cursor: "pointer",
                                             };

                                             return (
                                                <div
                                                   {...getSuggestionItemProps(
                                                      suggestion,
                                                      { style }
                                                   )}
                                                   key={cuid()}
                                                >
                                                   <span>
                                                      {suggestion.description}
                                                   </span>
                                                </div>
                                             );
                                          })}
                                       </div>
                                    </div>
                                 )}
                              </PlacesAutocomplete>
                           </Box>
                           <Box>
                              <Typography className='opacityGreenTitle'>
                                 Deliverable*
                              </Typography>
                              <Field
                                 component={TextField}
                                 select
                                 name='deliverable'
                                 variant='outlined'
                                 type='text'
                                 className='opacityGreenField'
                              >
                                 <MenuItem value='Strategy deck'>
                                    Strategy deck
                                 </MenuItem>
                                 <MenuItem value='Financial Model'>
                                    Financial Model
                                 </MenuItem>
                                 <MenuItem value='Plan for implementation of strategy'>
                                    Plan for implementation of strategy
                                 </MenuItem>
                                 <MenuItem value='Conference or meeting planned'>
                                    Conference or meeting planned
                                 </MenuItem>
                                 <MenuItem value='New Article of publication'>
                                    New Article of publication
                                 </MenuItem>
                                 <MenuItem value='Chapter(s) of a book'>
                                    Chapter(s) of a book
                                 </MenuItem>
                              </Field>
                           </Box>
                           <Box>
                              <Typography className='opacityGreenTitle'>
                                 Start Date
                              </Typography>
                              <Field
                                 component={DatePicker}
                                 name='startDate'
                                 inputVariant='outlined'
                                 id='startDate'
                                 className='opacityGreenField'
                              />
                           </Box>
                           <Box display="flex" alignSelf="flex-end">
                              <Typography>Unpaid</Typography>
                              <Box>
                                 <FormControlLabel
                                    control={
                                       <Field
                                          component={Switch}
                                          type='checkbox'
                                          name='paid'
                                       />
                                    }
                                    label=''
                                 />
                              </Box>
                              <Typography>Paid</Typography>
                           </Box>
                        </Box>
                        <Box>
                           <Box display='flex'>
                              <Box style={{ width: "65%" }}>
                                 <Typography id='projectDescriptionTitle'>
                                    Project Description*
                                 </Typography>
                                 <Field
                                    as='textarea'
                                    name='projectDescription'
                                    placeholder='Please describe project scope, team size, expected deliverable, compensation (if applicable), and any other relevant details you would like to share.'
                                    type='text'
                                    variant='outlined'
                                    id='projectDescription'
                                 />
                              </Box>

                              <Box
                                 display='inline-grid'
                                 style={{
                                    marginTop: "1.5%",
                                    marginLeft: "10%",
                                 }}
                              >
                                 <Typography>Project Sponsor</Typography>
                                 <Field
                                    component={TextField}
                                    name='sponsorName'
                                    type='text'
                                    variant='outlined'
                                    placeholder='Sponsor name*'
                                 />
                                 <Field
                                    component={TextField}
                                    name='sponsorRole'
                                    type='text'
                                    variant='outlined'
                                    placeholder='Sponsor role'
                                 />
                                 <Field
                                    component={TextField}
                                    name='sponsorEmail'
                                    type='text'
                                    variant='outlined'
                                    placeholder='Sponsor email*'
                                 />
                              </Box>
                           </Box>

                           <Box id='companyDescriptionBox'>
                              <Box style={{ width: "66%" }}>
                                 <Typography id='companyDescriptionTitle'>
                                    Company Description (if involved)
                                 </Typography>
                                 <Field
                                    as='textarea'
                                    name='companyDescription'
                                    type='text'
                                    variant='outlined'
                                    id='companyDescription'
                                 />
                              </Box>
                              <Box alignSelf='flex-end' id='submitButtonBox'>
                                 <Button
                                    id='submitButton'
                                    variant='outlined'
                                    color='primary'
                                    disabled={!dirty || !isValid || disabled}
                                    onClick={submitForm}
                                 >
                                    Submit
                                 </Button>
                              </Box>
                           </Box>
                        </Box>
                     </Form>
                  </MuiPickersUtilsProvider>
               )}
            </Formik>
         </DialogContent>
      </Dialog>
   );
}
