import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../../pages/DrawerContent.css";
import ProjectsFilter from "./ProjectsFilter";
import ProjectsList from "./ProjectsList";
import "firebase/functions";
import firebase from "firebase/app";

export default function ProjectsPage() {
   const [userData, setUserData] = useState<UserData>();
   const dispatch = useDispatch();

   useEffect(() => {
      let bizProjectsGetAcl = firebase
         .app()
         .functions("europe-west3")
         .httpsCallable("bizProjectsGetAcl");
      bizProjectsGetAcl().then(function (result) {
         let obj = result.data;
         setUserData(obj);
      });
   }, [dispatch]);

   interface UserData {
      isInsead: boolean;
      isEditor: boolean;
   }

   return (
      <div>
         <ProjectsFilter userData={userData} />
         <ProjectsList userData={userData} />
      </div>
   );
}
