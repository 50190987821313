import {
   Box,
   Button,
   Card,
   CardContent,
   IconButton,
   Typography,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import { useSelector } from "react-redux";
import {
   selectFavoritesProjects,
   selectPublishedProjects,
   selectUnpublishedProjects,
} from "../ProjectsSlice";
import CloseIcon from "@material-ui/icons/Close";

export default function ProjectViewScenery({
   userData,
   currentProject,
   handleSceneryChange,
   updatePublishedState,
   industries,
   changeCurrentProjectPlus,
   changeCurrentProjectMinus,
   comments,
   setComments,
   submitComments,
   currentIndex,
   favoriteOpen,
   setDialogOpen,
}) {
   const publishedProjects = useSelector(selectPublishedProjects);
   const unpublishedProjects = useSelector(selectUnpublishedProjects);
   const favoritesProjects = useSelector(selectFavoritesProjects);

   let currentLength: number;
   if (favoriteOpen) {
      currentLength = favoritesProjects.length;
   } else {
      currentLength = currentProject.published
         ? publishedProjects.length
         : unpublishedProjects.length;
   }

   return (
      <>
         <Box id='greenHeadView'>
            {userData.isInsead && userData.isEditor && (
               <Box id='topBoxGreen'>
                  <Typography id='publishState' component='div'>
                     {currentProject?.published
                        ? `Published ${currentProject?.firstPublishDate?.slice(
                             0,
                             15
                          )}`
                        : "Unpublished"}
                  </Typography>
                  <Box>
                     <Button
                        className='topButtons'
                        variant='outlined'
                        onClick={handleSceneryChange}
                     >
                        Edit
                     </Button>
                     <Button
                        onClick={() =>
                           (window.location.href = `mailto:${currentProject?.projectSponsor?.email}?subject=Business%20projects%20editor%20request`)
                        }
                        className='topButtons'
                        variant='outlined'
                     >
                        Contact Author
                     </Button>
                     <Button
                        className='topButtons'
                        variant='outlined'
                        onClick={updatePublishedState}
                     >
                        {currentProject?.published ? "Unpublish" : "Publish"}
                     </Button>
                     <IconButton
                        className='topButtons closeIconButton'
                        onClick={() => setDialogOpen(false)}
                     >
                        <CloseIcon />
                     </IconButton>
                  </Box>
               </Box>
            )}

            <Typography id='projectTitleView'>
               {currentProject?.projectTitle}
            </Typography>
            {currentProject?.companyWebsite && (
               <a
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ textDecoration: "none" }}
                  href={`https://${currentProject?.companyWebsite}`}
               >
                  <Typography id='companyNameView'>
                     {currentProject?.companyName ||
                        currentProject?.companyWebsite}
                  </Typography>
               </a>
            )}
            {currentProject?.companyName && !currentProject?.companyWebsite && (
               <Typography id='companyNameView'>
                  {currentProject?.companyName}
               </Typography>
            )}
         </Box>

         <Box
            id='opacityGreenView'
            style={{ display: "flex", flexWrap: "wrap" }}
         >
            <Box className='opacityGreenBox'>
               <Typography className='opacityGreenTitleView'>
                  Industries
               </Typography>
               <Typography
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className='opacityGreenPropertyView'
                  component='div'
               >
                  {industries.sort().join(", ")}
               </Typography>
            </Box>
            <Box className='opacityGreenBox'>
               <Typography className='opacityGreenTitleView'>Type</Typography>
               <Typography className='opacityGreenPropertyView'>
                  {currentProject?.type}
               </Typography>
            </Box>
            <Box className='opacityGreenBox'>
               <Typography className='opacityGreenTitleView'>
                  Duration
               </Typography>
               <Typography className='opacityGreenPropertyView'>
                  {currentProject?.duration}
               </Typography>
            </Box>{" "}
            <Box className='opacityGreenBox'>
               <Typography className='opacityGreenTitleView'>
                  Effort (weekly effort)
               </Typography>
               <Typography className='opacityGreenPropertyView'>
                  {currentProject?.effort}
               </Typography>
            </Box>{" "}
            <Box className='opacityGreenBox'>
               <Typography className='opacityGreenTitleView'>
                  Location
               </Typography>
               <Typography className='opacityGreenPropertyView'>
                  {currentProject?.location}
               </Typography>
            </Box>{" "}
            <Box className='opacityGreenBox'>
               <Typography className='opacityGreenTitleView'>
                  Paid
               </Typography>
               <Typography className='opacityGreenPropertyView'>
                  {currentProject?.paid ? "Yes" : "No"}
               </Typography>
            </Box>
            <Box className='opacityGreenBox'>
               <Typography className='opacityGreenTitleView'>
                  Deliverable
               </Typography>
               <Typography className='opacityGreenPropertyView'>
                  {currentProject?.deliverable}
               </Typography>
            </Box>
            <Box className='opacityGreenBox'>
               <Typography className='opacityGreenTitleView'>
                  Start Date
               </Typography>
               <Typography className='opacityGreenPropertyView'>
                  {currentProject?.startDate?.slice(0, 15)}
               </Typography>
            </Box>
         </Box>

         <Box id='projectDescriptionSponsor' display='flex'>
            <Box id='projectDescriptionBox'>
               <Typography id='projectTitleWhiteBox'>
                  Project description
               </Typography>
               <Typography id='projectDescriptionWhiteBox'>
                  {currentProject?.projectDescription}
               </Typography>
            </Box>
            <Card variant='outlined' className='projectDescriptionCard'>
               <CardContent>
                  <Typography id='projectSponsorWhiteBox'>
                     Project Sponsor
                  </Typography>
                  <Typography id='projectSponsorNameWhiteBox'>
                     {currentProject?.projectSponsor?.name}
                  </Typography>
                  <Typography id='projectSponsorRoleWhiteBox'>
                     {currentProject?.projectSponsor?.role}
                  </Typography>
                  <Button
                     id='contactMeButton'
                     onClick={() =>
                        (window.location.href = `mailto:${currentProject?.projectSponsor?.email}?subject=I'm%20interested%20in%20your%20business%20project`)
                     }
                     variant='outlined'
                  >
                     Contact me to start project
                  </Button>
               </CardContent>
            </Card>
         </Box>
         <Box>
            {currentIndex !== 0 && (
               <IconButton
                  id='arrowBack'
                  onClick={() => changeCurrentProjectMinus()}
               >
                  <ArrowBackIosIcon
                     style={{ fontSize: "50px", color: "#DDDDDD" }}
                  />
               </IconButton>
            )}
            {currentIndex !== currentLength - 1 && (
               <IconButton
                  id='arrowForward'
                  onClick={() => changeCurrentProjectPlus()}
               >
                  <ArrowForwardIosIcon
                     style={{ fontSize: "50px", color: "#DDDDDD" }}
                  />
               </IconButton>
            )}
         </Box>
         {userData.isInsead && userData.isEditor && (
            <Box id='commentsBox'>
               <Box style={{ width: "70%" }}>
                  <Typography id='commentsTitle'>Comments (editors)</Typography>
                  <textarea
                     value={comments}
                     onChange={(e) => setComments(e.target.value)}
                     style={{ width: "100%", height: "200px" }}
                  />
               </Box>
               <Box id='submitButtonBox'>
                  <Button
                     id='submitButton'
                     style={{ width: "80px" }}
                     variant='outlined'
                     onClick={submitComments}
                     disabled={currentProject?.comments === comments}
                  >
                     Submit
                  </Button>
               </Box>
            </Box>
         )}
      </>
   );
}
