import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthenticationTokens } from "../../features/localstorage/localStorageSlice";
import {
   successfulLogin,
   signOut,
   failedLogin,
   selectLoadingAuth,
} from "../../features/authentication/authenticationSlice";
import { routeHistory } from "../../routing/history";
import { Box, Button, IconButton } from "@material-ui/core";
import { Route } from "react-router-dom";
import PrivateRoute from "../../routing/PrivateRoute";
import firebase from "firebase/app";
import "firebase/auth";
import { UserDetail } from "../../types/UserDetail";
import { signOutAsync } from "../../features/authentication/authenticationSlice";
import "./AppMain.css";
import "../../pages/DrawerContent.css";
import FAQ from "../../pages/FAQ";
import Projects from "../../pages/projects/projectsPage/ProjectsPage";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      contentBoard: {
         flexGrow: 1,
      },
      root: {
         flexGrow: 1,
         position: "relative",
         fontFamily: "Roboto",
      },
      green: {
         color: "#00691D",
         textTransform: "none",
         fontFamily: "Arial",
         fontWeight: 700,
         fontStyle: "normal",
         lineHeight: "20.7px",
      },
      grey: {
         color: "#757575",
         textTransform: "none",
         fontFamily: "Arial",
         fontWeight: 700,
         fontStyle: "normal",
         lineHeight: "20.7px",
      },
   })
);

export default function AppMain() {
   const classes = useStyles();
   const dispatch = useDispatch();
   const authLoading = useSelector(selectLoadingAuth);
   const userIsLoggedIn = authLoading === "succeeded" ? "true" : false;

   const path = window.location.pathname;
   const loc = path.split("/");

   const onSignOutClick = () => {
      dispatch(signOutAsync());
      dispatch(clearAuthenticationTokens());
      dispatch(signOut());
      routeHistory.push("/login");
   };

   const handleLogin = () => {
      routeHistory.push("/login");
   };

   firebase.auth().onAuthStateChanged((user) => {
      if (user) {
         const currentUser = {
            email: user.email,
            token: localStorage.getItem("tumla-token"),
         } as UserDetail;
         dispatch(successfulLogin(currentUser));
      } else {
         dispatch(failedLogin());
         console.log("no user logged in");
      }
   });

   return (
      <div className={classes.root}>
         <AppBar position='static'>
            <Toolbar>
               <div className={classes.contentBoard}>
                  <img src='Insead.png' alt='Insead'></img>
               </div>
               <Box>
                  <Button
                     className={loc[1] === "" ? classes.green : classes.grey}
                     onClick={() => routeHistory.push("/")}
                  >
                     Home
                  </Button>
                  <Button
                     className={
                        loc[1] === "projects" ? classes.green : classes.grey
                     }
                     onClick={() => routeHistory.push("/projects")}
                  >
                     Projects
                  </Button>
                  <Button
                     className={
                        loc[1] === "faq-contacts" ? classes.green : classes.grey
                     }
                     onClick={() => routeHistory.push("/faq-contacts")}
                  >
                     FAQ & Contacts
                  </Button>
                  <Button
                     className={
                        loc[1] === "project-sponsors"
                           ? classes.green
                           : classes.grey
                     }
                     onClick={() => routeHistory.push("/project-sponsors")}
                  >
                     For Project Sponsors
                  </Button>
                  <IconButton
                     className={classes.grey}
                     onClick={userIsLoggedIn ? onSignOutClick : handleLogin}
                  >
                     {userIsLoggedIn ? <ExitToAppIcon /> : <VpnKeyIcon />}
                  </IconButton>
               </Box>
            </Toolbar>
         </AppBar>
         {loc[1] !== "login" && loc[1] !== "forgot-password" && (
            <div>
               <main>
                  <Route exact path='/' component={FAQ} />
                  <PrivateRoute exact path='/projects' component={Projects} />
                  <Route exact path='/faq-contacts' component={FAQ} />
                  <Route exact path='/project-sponsors' component={FAQ} />
               </main>
               <div
                  style={{
                     bottom: 0,
                     width: "100%",
                     height: "230px",
                     paddingTop: "1%",
                  }}
               >
                  <div
                     style={{
                        backgroundColor: "#002417",
                        bottom: "0",
                        height: "130px",
                        width: "100%",
                     }}
                  ></div>
                  <div
                     style={{
                        backgroundColor: "#004A30",
                        bottom: "130px",
                        height: "100px",
                        width: "100%",
                     }}
                  ></div>
               </div>
            </div>
         )}
      </div>
   );
}
